<template>
    <div>
        <div class="stats-cards">
            <div class="card">
                <p class="card-number">{{ totalBinaryActives }}</p>
                <h3 class="card-title">Total de Binários Ativos</h3>
            </div>
            <div class="card">
                <p class="card-number">{{ parseFloat(totalPointsEarned).toFixed(2) }}</p>
                <h3 class="card-title">Total de Pontos Pagos</h3>
            </div>
            <div class="card">
                <p class="card-number">{{ parseFloat(totalPointsToPay).toFixed(2) }}</p>
                <h3 class="card-title">Total de Pontos a Pagar</h3>
            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Pontos Perna Esquerda</th>
                    <th>Pontos Perna Direita</th>
                    <th>Pontos Ganhos</th>
                    <th>Pontos À Receber</th>
                    <th>Limite diário</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in sortedReport" :key="item._id">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <div>{{ item.idUser.fullname }}</div>
                        <div>{{ item.idUser.email }}</div>
                    </td>
                    <td>{{ parseFloat(item.pointsChildLeft).toFixed(2) || 0 }}</td>
                    <td>{{ parseFloat(item.pointsChildRight).toFixed(2) || 0 }}</td>
                    <td>{{ parseFloat(item.pointsEarned).toFixed(2) || 0 }}</td>
                    <td>{{ calculateDifference(item.pointsChildLeft, item.pointsChildRight, item.pointsEarned).toFixed(2) }}</td> <!-- Cálculo da diferença -->
                    <td>{{ parseFloat(item.dailyBinaryLimit).toFixed(2) || 0 }}</td>                    
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            report: [],
            binaryPaymentDetails: {},
            totalPointsEarned: 0,
            totalBinaryActives: 0,
            totalPointsToPay: 0,
        };
    },
    computed: {
        sortedReport() {
            return this.report.slice().sort((a, b) => (b.pointsEarned || 0) - (a.pointsEarned || 0));
        },
    },
    mounted() {
        this.fetchReportBinary();
    },
    methods: {
        async fetchReportBinary() {
            await this.$store.dispatch('fetchReportBinary').then(resp => {
                this.report = resp.userBinary;
                this.binaryPaymentDetails = resp.percentPay;
                this.computeStatistics();
            });
        },
        computeStatistics() {
            this.totalPointsEarned = this.report.reduce((acc, item) => acc + (item.pointsEarned || 0), 0);
            this.totalBinaryActives = this.report.length;
            this.totalPointsToPay = this.report.reduce(
                (acc, item) => acc + this.calculateDifference(item.pointsChildLeft, item.pointsChildRight, item.pointsEarned),
                0
            );
        },
        calculateDifference(pointsChildLeft, pointsChildRight, pointsEarned) {
            const minPoints = Math.min(pointsChildLeft || 0, pointsChildRight || 0);
            return minPoints - (pointsEarned || 0);
        },
        moneyFormat(value) {
            return parseFloat(value).toFixed(2);
        },
    },
};
</script>

<style scoped>
.stats-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    flex: 1 1 calc(20% - 20px);
    padding: 20px;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
}

/* Tamanho da fonte personalizado */
.card-title {
    font-size: 14px; /* Título menor */
    color: #666;
}

.card-number {
    font-size: 24px; /* Número maior */
    font-weight: bold;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: left;
}
</style>
